<template>
  <d-edit-card
    :key="$route.path"
    :id="isEditingForm ? $route.params.id : undefined"
    :fetch-function="loadPaymentRequisiteFilters"
    :entity="form"
    :prev-page="redirectionPage"
    :card-title="`Payment requisite filters ${
      isEditingForm ? 'updating' : 'creation'
    }`"
    remove-confirm-title="Are you sure you want to delete this payment requisite filters?"
    remove-success-title="Payment requisite filters successfully removed"
    :remove-function="paymentRequisitesController.removePaymentRequisiteFilter"
    :submit-function="submitForm"
    redirect-after-creation="PaymentRequisiteFilterEdit"
    :submit-success-title="`Payment requisite filters ${
      this.isEditingForm ? 'updated' : 'created'
    }`"
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div
            class="col-xs-12 col-md-6 q-col-gutter-md"
            :class="$q.screen.lt.md ? 'q-mb-md' : 'q-pr-md'"
          >
            <q-input
              v-model.trim="data.name"
              outlined
              label="Name"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-toggle
              dense
              v-model="data.active"
              checked-icon="mdi-check"
              color="green"
              size="xl"
              label="Status"
              unchecked-icon="mdi-close"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <q-input
              label="Payload"
              input-class="text-overline"
              :rules="[rules.notNullField, rules.checkRegExp]"
              outlined
              v-model.trim="data.payload"
              debounce="300"
              :autogrow="$q.screen.lt.md"
              type="textarea"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading, remove }">
      <d-edit-form-actions
        :class="$q.screen.lt.md && 'full-width'"
        :is-editing-form="isEditingForm"
        :prev-page="redirectionPage"
        @remove="remove"
      />
    </template>
  </d-edit-card>
</template>

<script>
import { DEditFormActions } from '@/features/edit-form-actions';
import DEditCard from '@/layouts/edit-card';
import { paymentRequisitesController as apiController } from '@/shared/api';
import { ROLES } from '@/shared/constants';

export default {
  components: { DEditCard, DEditFormActions },
  data: () => ({
    rules: {
      notNullField: (val) => (val && val.length > 0) || 'Please type value',
      checkRegExp: async (json) => {
        try {
          const values = [];
          const iterate = (obj) => {
            for (const key in obj) {
              if (typeof obj[key] === 'object' && obj[key] !== null) {
                iterate(obj[key]);
              } else {
                values.push(obj[key]);
              }
            }
            return !values.includes(null) || "Contains 'null'";
          };

          return iterate(JSON.parse(json));
        } catch (e) {
          return 'Invalid JSON';
        }
      },
    },
    form: {
      active: true,
      name: '',
      payload: [],
      id: null,
    },
    paymentRequisitesController: apiController('adminFilter'),
  }),

  computed: {
    isEditingForm: ({ $route }) => !!$route.meta.isEdit,
    redirectionPage() {
      return this.$store.getters.GET_USER_ROLES.includes(ROLES.ROLE_AGENT)
        ? 'PaymentRequisitesEdit'
        : 'PaymentRequisitesList';
    },
  },

  methods: {
    async loadPaymentRequisiteFilters(id) {
      const { data } =
        await this.paymentRequisitesController.getPaymentRequisiteFilter(id);

      return {
        data: {
          ...data,
          payload: JSON.stringify(data.payload, null, 2),
        },
      };
    },

    async submitForm({ id, payload, ...data }) {
      const action = this.isEditingForm
        ? this.paymentRequisitesController.updatePaymentRequisiteFilter
        : this.paymentRequisitesController.createPaymentRequisiteFilter;

      await action({
        id: this.$route.params.id,
        payload: {
          id: id ?? undefined,
          payload: JSON.parse(payload),
          ...data,
        },
      });

      return { data: { id: this.$route.params.id } };
    },
  },
};
</script>
